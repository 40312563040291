<template>
  <b-row class="hp-authentication-page">
    <b-col cols="12" lg="6" class="bg-primary-4 hp-bg-color-dark-90">
      <b-row
        class="hp-image-row h-100 px-8 px-sm-16 px-md-0 pb-32 pb-sm-0 pt-32 pt-md-0"
      >
        <b-col cols="12" class="hp-logo-item m-16 m-sm-32 m-md-64 w-auto px-0">
          <img
            :src="
              this.$store.state.themeConfig.theme == 'dark'
                ? require('@/assets/img/logo/unoobs_logo.png')
                : require('@/assets/img/logo/unoobs_logo.png')
            "
            alt="Logo"
            width="15%"
          />
        </b-col>

        <b-col cols="12">
          <b-row align-v="center" align-h="center" class="h-100">
            <b-col
              cols="12"
              md="8"
              class="hp-bg-item text-center mb-32 mb-md-0"
            >
              <img
                class="m-auto image"
                :src="
                  this.$store.state.themeConfig.theme == 'dark'
                    ? require('@/assets/img/pages/authentication/core_values.png')
                    : require('@/assets/img/pages/authentication/core_values.png')
                "
                alt="Background Image"
              />
            </b-col>

            <b-col cols="12" md="11" lg="10" class="hp-text-item text-center">
              <h2
                class="text-primary-1 hp-text-color-dark-0 mx-16 mx-lg-0 mb-16"
              >
                Very good things are waiting for you 🤞
              </h2>

              <p class="mb-0 text-black-80 hp-text-color-dark-30">
                To access the Undisputed Noobs member portal you must have
                created your account and submitted your application
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" lg="6" class="hp-error-content py-32 text-center">
      <b-row align-v="center" align-h="center" class="row h-100">
        <b-col cols="12">
          <div class="position-relative mt-0 mt-sm-0 mb-0">
            <h2 class="h1 mb-0 mb-sm-8" v-if="applicationStatus === 'approved'">
              You're onboarded!
            </h2>
            <h2 class="h1 mb-0 mb-sm-8" v-else>You're not onboarded yet</h2>
            <b-row align-v="center" align-h="center">
              <b-col cols="12" lg="6">
                <div v-if="applicationStatus === 'pending interview'">
                  <br />
                  <b-alert variant="info" show>
                    Your application is pending an interview, check our Discord!
                  </b-alert>
                </div>
                <div v-if="applicationStatus === 'submitted'">
                  <br />
                  <b-alert variant="warning" show>
                    Your application is submitted, please allow up to 5 open
                    days.
                  </b-alert>
                </div>
                <div v-if="applicationStatus === 'rejected'">
                  <br />
                  <b-alert variant="danger" show>
                    Oh no! Your application has been rejected :(<br />Reason:
                    {{ applicationRejectionReason }}
                  </b-alert>
                </div>
                <div v-if="applicationStatus === 'approved'">
                  <br />
                  <b-alert variant="success" show>
                    Congratulations🎉 Your application has been approved, you
                    can now log out and log back in.
                  </b-alert>
                </div>
              </b-col>
            </b-row>
            <img
              v-bind:src="require('@/assets/img/pages/error/waiting.svg')"
              class="d-block mx-auto position-relative"
              alt="Not onboarded yet"
              style="max-width: 450px; max-height: 450px"
            />
          </div>

          <br />

          <b-row align-v="center" align-h="center" class="text-left mb-16">
            <b-col cols="12" lg="10">
              <b-card v-if="applicationStatus == 'submitted'">
                <h4 class="mb-1">Application Status</h4>
                <p class="text-black-60">(ID #{{ applicationId }})</p>
                <b-card-text>
                  Our team is reviewing your application. Keep an eye on
                  <a href="https://discord.gg/undisputednoobs" target="_blank"
                    >our Discord</a
                  >
                  for updates
                  <b-alert variant="warning" show class="mt-16 mb-0">
                    <h3 class="text-warning text-left"><b>Reminder</b></h3>
                    Enable your notifications on our Discord server to be kept
                    up to date on the progress of your interview and other
                    important notifications.
                  </b-alert>
                </b-card-text>
              </b-card>
              <b-card v-if="applicationStatus == 'pending interview'">
                <h4 class="mb-1">Application Status</h4>
                <p class="text-black-60">(ID #{{ applicationId }})</p>
                <b-card-text>
                  Good News! Your application is pending an interview. Go and
                  check out our Discord.
                  <br /><br />
                  <b-alert variant="warning" show class="mt-16 mb-0">
                    <h3 class="text-warning text-left"><b>Reminder</b></h3>
                    Enable your notifications on our Discord server to be kept
                    up to date on the progress of your interview and other
                    important notifications.
                  </b-alert>
                  In the meantime you can prepare your interview by choosing the
                  date and time you want to proceed with the following link.
                  <b-link :href="calendlylink" target="_blank">
                    {{ calendlylink }}
                  </b-link>
                </b-card-text>
              </b-card>
              <b-card v-if="applicationStatus == 'approved'">
                <h4 class="mb-1">Application Status</h4>
                <p class="text-black-60">(ID #{{ applicationId }})</p>
                <b-card-text>
                  Your application has been approved, you can now log out and
                  log back in!
                </b-card-text>
              </b-card>
              <b-card v-if="applicationStatus == 'rejected'">
                <h4 class="mb-1">Application Status</h4>
                <p class="text-black-60">(ID #{{ applicationId }})</p>
                <b-card-text>
                  Unfortunately your application has been rejected. If you think
                  this is a mistake, please contact us on Discord.
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>

          <b-button to="/logout" variant="primary">
            <span>Logout</span>
          </b-button>
          &nbsp;
          <b-button v-b-modal.edit-application variant="warning">
            <span>Edit Application</span>
          </b-button>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            If you have just been accepted, you must log in again. Use the
            Logout button above.
          </p>
        </b-col>
        <footer-item />
      </b-row>
    </b-col>

    <b-modal
      id="edit-application"
      ref="modal"
      size="lg"
      @ok="handleOk"
      cancel-variant="text"
      header-class="align-items-center"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">Edit your application</h5>

        <b-button
          variant="text"
          @click="close()"
          class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
          style="min-height: 24px"
        >
          <i
            class="ri-close-line hp-text-color-dark-0 lh-1"
            style="font-size: 24px"
          ></i>
        </b-button>
      </template>

      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="First Name"
              label-for="firstname-input"
              invalid-feedback="First name is required"
              :state="userApplication.firstnameState"
            >
              <b-form-input
                id="name-input"
                v-model="userApplication.firstname"
                :state="userApplication.firstnameState"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Last Name"
              label-for="lastname-input"
              invalid-feedback="Last name is required"
              :state="userApplication.lastnameState"
            >
              <b-form-input
                id="name-input"
                v-model="userApplication.lastname"
                :state="userApplication.lastnameState"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Username (can't be changed)"
              label-for="username-input"
              invalid-feedback="Username is required"
              :state="userApplication.usernameState"
            >
              <b-form-input
                id="name-input"
                v-model="userApplication.username"
                :state="userApplication.usernameState"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Email"
              label-for="email-input"
              invalid-feedback="Email is required"
              :state="userApplication.emailState"
            >
              <b-form-input
                id="name-input"
                v-model="userApplication.email"
                :state="userApplication.emailState"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Phone Number"
              label-for="phonenumber-input"
              invalid-feedback="Phone number is required"
              :state="userApplication.phonenumberState"
            >
              <b-form-input
                id="name-input"
                v-model="userApplication.phonenumber"
                :state="userApplication.phonenumberState"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Wallet Metamask"
              label-for="walletmetamask-input"
              invalid-feedback="Wallet Metamask is required"
              :state="userApplication.walletmetamaskState"
            >
              <b-form-input
                id="walletmetamask-input"
                v-model="userApplication.walletmetamask"
                :state="userApplication.walletmetamaskState"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Wallet Solana"
              label-for="walletsolana-input"
              invalid-feedback="Wallet Solana is required"
              :state="userApplication.walletsolanaState"
            >
              <b-form-input
                id="walletetherscan-input"
                v-model="userApplication.walletsolana"
                :state="userApplication.walletsolanaState"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Wallet Ronin"
              label-for="walletronin-input"
              invalid-feedback="Wallet Ronin is required"
              :state="userApplication.walletroninState"
            >
              <b-form-input
                id="walletmetamask-input"
                v-model="userApplication.walletronin"
                :state="userApplication.walletroninState"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Wallet Wax"
              label-for="walletwax-input"
              invalid-feedback="Wallet Wax is required"
              :state="userApplication.walletWaxState"
            >
              <b-form-input
                id="walletetherscan-input"
                v-model="userApplication.walletwax"
                :state="userApplication.walletwaxState"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Game(s) you are interested in"
            >
              <b-form-checkbox
                v-for="option in options"
                v-model="userApplication.gamesInterestedIn"
                :key="option.value"
                :value="option.value"
                :aria-describedby="ariaDescribedby"
                name="gamesinterestedin"
              >
                {{ option.text }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BAlert,
  BLink,
} from "bootstrap-vue";
import FooterItem from "../FooterItem.vue";
import userService from "../../../../services/user.service";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    FooterItem,
    BCard,
    BCardText,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormCheckbox,
    BAlert,
    BLink,
  },
  data() {
    return {
      applicationStatus: null,
      applicationRejectionReason: null,
      applicationId: "undefined",
      userApplication: {
        // State
        usernameState: null,
        firstnameState: null,
        lastnameState: null,
        emailState: null,
        phonenumberState: null,
        reasonwhyState: null,
        walletmetamaskState: null,
        walletroninState: null,
        walletsolanaState: null,
        walletwaxState: null,
        gamesInterestedInState: null,

        // Data
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        reasonwhy: "",
        walletmetamask: "",
        walletronin: "",
        walletsolana: "",
        walletwax: "",
        gamesInterestedIn: [],
        agent: "",
      },
      calendlylink: "https://calendly.com/undisputednoobs/onboarding",
      options: [
        { text: "Sunflower Land", value: "sunflowerland" },
        { text: "Million on Mars", value: "milliononmars" },
        { text: "Genopets", value: "genopets" },
        { text: "Legends of Venari", value: "legendsofvenari" },
        { text: "Honeyland", value: "honeyland" },
        { text: "Axie Infinity", value: "axieinfinity" },
        { text: "Illuvium", value: "illuvium" },
        { text: "Gods Unchained", value: "godsunchained" },
        { text: "Bigtime", value: "bigtime" },
      ],
    };
  },
  async mounted() {
    var username = this.$store.state.auth.user.username;
    var useruuid = this.$store.state.auth.user.useruuid;

    userService.getUserApplication(username, useruuid).then(
      (userApplication) => {
        this.applicationStatus = userApplication.data.status || "";
        this.applicationRejectionReason =
          userApplication.data.rejectionReason || "";
        this.userApplication.username = userApplication.data.username || "";
        this.userApplication.firstname = userApplication.data.firstName || "";
        this.userApplication.lastname = userApplication.data.lastName || "";
        this.userApplication.email = userApplication.data.email || "";
        this.userApplication.phonenumber =
          userApplication.data.phoneNumber || "";
        this.userApplication.reasonwhy = userApplication.data.reasonWhy || "";
        this.userApplication.walletmetamask =
          userApplication.data.walletMetamask || "";
        this.userApplication.walletronin =
          userApplication.data.walletRonin || "";
        this.userApplication.walletsolana =
          userApplication.data.walletSolana || "";
        this.userApplication.walletwax = userApplication.data.walletWax || "";
        this.userApplication.gamesInterestedIn =
          userApplication.data.gamesInterestedIn || [];
        this.applicationId = userApplication.data.applicationId || "undefined";
        this.userApplication.agent = userApplication.data.agent || "";
      },
      (error) => {
        //console.log(error);
      }
    );

    if (this.userApplication.agent == "Catasus") {
      CalendlyLink = "https://calendly.com/catasus/undisputednoobs_onboarding"; //Catasus
    } else if (this.userApplication.agent == "gracie") {
      CalendlyLink = "https://calendly.com/unoobs_gracie/30min"; //Gracie
    }
  },
  methods: {
    resetState() {
      this.userApplication.usernameState = null;
      this.userApplication.firstnameState = null;
      this.userApplication.lastnameState = null;
      this.userApplication.emailState = null;
      this.userApplication.phonenumberState = null;
      this.userApplication.reasonwhyState = null;
      this.userApplication.walletmetamaskState = null;
      this.userApplication.walletroninState = null;
      this.userApplication.walletsolanaState = null;
      this.userApplication.walletwaxState = null;
      this.userApplication.gamesInterestedInState = null;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.userApplication.usernameState = valid;
      this.userApplication.firstnameState = valid;
      this.userApplication.lastnameState = valid;
      this.userApplication.emailState = valid;
      this.userApplication.phonenumberState = valid;
      this.userApplication.reasonwhyState = valid;
      this.userApplication.walletmetamaskState = valid;
      this.userApplication.walletroninState = valid;
      this.userApplication.walletsolanaState = valid;
      this.userApplication.walletwaxState = valid;
      return valid;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      var username = this.$store.state.auth.user.username;
      var useruuid = this.$store.state.auth.user.useruuid;
      userService
        .updateUserApplication(username, useruuid, this.userApplication)
        .then(
          (response) => {
            this.resetState();
            this.$bvToast.toast(
              "Your application has been updated successfully",
              {
                title: `Application Updated!`,
                variant: "success",
                solid: true,
              }
            );
            this.$bvModal.hide("edit-application");
          },
          (error) => {
            //console.log(error);
            this.resetState();
            this.$bvToast.toast(
              "Unfortunately we can't update your application now.",
              {
                title: `Update Error`,
                variant: "danger",
                solid: true,
              }
            );
            this.$bvModal.hide("edit-application");
          }
        );
    },
  },
};
</script>
