<template>
  <b-col cols="12" class="py-24">
    <p class="mb-0 hp-badge-text">
      COPYRIGHT ©2022 Undisputed Noobs, All rights Reserved
    </p>
  </b-col>
</template>

<script>
import { BCol } from "bootstrap-vue";

export default {
  components: {
    BCol,
  },
};
</script>
